<template>
  <div class="page">
    <div class="title">
      <div class="name">不文明行为</div>
      <div class="search">
        <!-- <Select
          @keydown.native.enter.prevent="search()"
          v-model="formItem.vProjectId"
          placeholder="所属项目"
          filterable
          clearable
          style="width: 120px"
        >
          <Option
            :value="item.v_project_id"
            v-for="(item, index) in elevatorFormItemProjectSelectList"
            :key="index"
          >
            {{ item.v_project_name }}</Option
          >
        </Select> -->
        <Select
          v-model="formItem.villageId"
          placeholder="所在小区"
          clearable
          style="width:120px"
        >
          <Option
            v-for="(item, index) in villageFormItemSelectList"
            :key="index"
            :value="item.v_village_id"
          >
            {{ item.v_village_name }}</Option
          >
        </Select>
        <Select
          v-model="formItem.vElevatorCode"
          placeholder="全部电梯"
          clearable
          style="width:120px"
        >
          <Option
            v-for="(item, index) in elevatorSelectList"
            :key="index"
            :value="item.v_elevator_code"
          >
            {{ item.v_elevator_name }}</Option
          >
        </Select>
        <!-- <Select
          v-model="formItem.iStatus"
          placeholder="不文明行为状态"
          clearable
          style="width:120px"
        >
          <Option
            v-for="(item, index) in faultDefinitionList"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}</Option
          >
        </Select> -->
        <Select
          v-model="formItem.iFaultType"
          placeholder="不文明类型"
          clearable
          style="width:120px"
        >
          <Option
            v-for="(item, index) in faultStatusList"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}</Option
          >
        </Select>
        <DatePicker
          @on-change="formItem.dtReportTime = $event"
          :value="formItem.dtReportTime"
          format="yyyy-MM-dd"
          type="date"
          style="width:120px"
          placeholder="开始时间"
        />
        <DatePicker
          @on-change="onChangeEndTime"
          :value="formItem.dtEndTime"
          format="yyyy-MM-dd"
          type="date"
          style="width:120px"
          placeholder="结束时间"
        />
        <Button type="primary" @click="searchList()">查询</Button>
      </div>
    </div>
    <div class="content">
      <tblcomponents
        ref="tblcomponents"
        :formItem="formItem"
        :tabelConfigVal="tabelConfig"
        :tabelDataVal="tabelData"
        :tabelLoadingVal="tabelLoading"
        :totalRecordCntVal="totalRecordCnt"
        @search="searchList"
        data-index="vDeptId"
      />
    </div>
    <Modal
      footer-hide
      :mask="true"
      :mask-closable="false"
      :scrollable="true"
      class-name="vertical-center-modal"
      title="详情"
      v-model="modaDetail"
      width="950"
    >
      <div style="background:#fff">
        <Row :gutter="10">
          <Col :lg="14" :md="14" :sm="14" :xs="14" class="flexVideo">
            <Tabs size="small">
              <TabPane label="视频" class="TimeDiv">
                <video
                  :src="videoUrl"
                  controls
                  ref="video"
                  v-if="videoUrl != '' && videoUrl != null"
                >
                  您的浏览器不支持video元素。
                </video>
                <!-- <svg-icon :icon-class="'notViodeo'" class="svg-icon_not_video" v-if="!videoUrl" /> -->
                <br />
                <font style="margin-left:205px" v-if="!videoUrl">暂无视频</font>
              </TabPane>
              <TabPane label="图片">
                <Scroll v-if="faultImageList.length !== 0">
                  <div
                    :key="index"
                    dis-hover
                    style="margin: 32px 0"
                    v-for="(item, index) in faultImageList"
                  >
                    <img :src="item" style="max-width:100%" />
                  </div>
                </Scroll>
                <!-- <svg-icon :icon-class="'notImgList'" class="svg-icon_not_video" v-if="faultImageList.length === 0" /> -->
                <br />
                <font
                  style="margin-left:210px"
                  v-if="faultImageList.length === 0"
                  >暂无图片</font
                >
              </TabPane>
            </Tabs>
          </Col>
          <Col :lg="10" :md="10" :sm="10" :xs="10">
            <CellGroup>
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.v_fault_id + '' : '--'
                "
                title="不文明行为ID"
              />
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.v_elevator_name + '' : '--'
                "
                title="电梯名称"
              />
              <Cell
                :extra="
                  faultInfoDetail.v_address
                    ? faultInfoDetail.v_address + ''
                    : '--'
                "
                title="电梯地址"
              />
              <Cell
                :extra="
                  faultInfoDetail.i_elevator_type
                    ? eveType(faultInfoDetail.i_elevator_type) + ''
                    : '--'
                "
                title="电梯类型"
              />
              <Cell
                :extra="
                  faultInfoDetail.dt_report_time
                    ? formatDateVal(faultInfoDetail.dt_report_time) + ''
                    : '--'
                "
                title="上报时间"
              />
              <Cell
                :extra="
                  faultInfoDetail.dt_end_time
                    ? formatDateVal(faultInfoDetail.dt_end_time) + ''
                    : '--'
                "
                title="结束时间"
              />
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.v_fault_name + '' : '--'
                "
                title="类型"
              />
              <!-- <Cell :extra="faultInfoDetail.v_fault_second_type ? faultInfoDetail.v_fault_second_type  + '-' + faultInfoDetail.v_fault_second_name + '' : '--'" title="子类型" /> -->
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.i_fault_num + '' : '--'
                "
                title="上报次数"
              />
              <Cell
                :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_mode_status_name + ''
                    : '--'
                "
                title="所处服务模式"
              />
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.i_status_name + '' : '--'
                "
                selected
                title="当前状态"
              />
            </CellGroup>
          </Col>
        </Row>
      </div>
    </Modal>
  </div>
</template>
<script>
import { getFaultDetail } from '@/api/repair/index.js'
import {
  reqElevatorProjectSelectList,
  searchVillageSelectList
} from '@/api/home/index.js'

import { searchFaultList } from '@/api/uncivilized/index.js'
import * as ElevatorApi from '@/api/elevator/index'

export default {
  data() {
    return {
      modaDetail: false,
      elevatorSelectList: [],
      iFaultTypeList: [
        {
          name: 'BYLX01',
          label: '半月保'
        },
        {
          name: 'BYLX02',
          label: '季度保'
        },
        {
          name: 'BYLX03',
          label: '半年保'
        },
        {
          name: 'BYLX04',
          label: '年度保'
        }
      ],
      iStatusList: [
        {
          name: 1,
          label: '渠道上报'
        },
        {
          name: 2,
          label: '新建工单'
        },
        {
          name: 3,
          label: '已接单'
        },
        {
          name: 4,
          label: '已签到'
        },
        {
          name: 5,
          label: '已完成'
        },
        {
          name: 6,
          label: '已确认'
        },
        {
          name: 7,
          label: '误报'
        }
      ],

      elevatorFormItemProjectSelectList: [],
      villageFormItemSelectList: [],
      faultImageList: '',
      model1: '',
      faultInfoDetail: {},
      videoUrl: '',
      searchLoading: false,
      open: false,
      tabelConfig: this.tableTool.setColumn([
        {
          title: '电梯名称',
          key: 'v_elevator_name',
          type: 'elevatorName'
        },

        {
          title: '所属小区',
          key: 'villageName',
          type: 'villageName'
        },
        {
          title: '级别',
          key: 'i_level_name',
          type: 'levelName'
        },
        {
          title: '安装地址',
          key: 'v_address',
          type: 'address'
        },
        {
          title: '上报时间',
          key: 'dt_report_time',
          type: 'datetime',
          render: (h, params) => {
            return h('div', [
              h('font', this.formatDateVal(params.row.dt_report_time))
            ])
          }
        },
        {
          title: '不文明行为类型',
          key: 'v_fault_name',
          type: 'faultName'
        },
        {
          title: '上报次数',
          key: 'i_fault_num',
          type: 'faultNum'
        },
        {
          title: '服务模式',
          key: 'i_mode_status_name',
          type: 'modeName'
        },
        {
          title: '状态',
          key: 'i_status_name',
          type: 'statusName',
          render: (h, params) => {
            if (params.row.i_status === 0) {
              return h('div', [
                h(
                  'font',
                  {
                    style: {
                      color: '#f52f77'
                    }
                  },
                  params.row.i_status_name
                )
              ])
            } else {
              return h('div', [
                h(
                  'font',
                  {
                    style: {
                      color: '#2db7f5'
                    }
                  },
                  params.row.i_status_name
                )
              ])
            }
          }
        },
        {
          title: '操作',
          key: 'action',
          type: 'operate',
          align: 'center',
          render: (h, params) => {
            const btns = []
            btns.push(
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.showDetail(params.row.v_fault_id)
                    }
                  }
                },
                '查看'
              )
            )
            return h('div', btns)
          }
        }
      ]),
      tabelData: [],
      tabelLoading: false,
      totalRecordCnt: 0,
      formItem: {
        vProjectId: '8089373891892543488',
        eType: null,
        villageId: '',
        vElevatorCode: '',
        dtReportTime: this.formatDate(-30),
        dtEndTime: this.formatDate(0),
        iUncivilizedBehaviorFlag: 1,
        iElevatorType: 1,
        iFaultType: '',
        vEventType: 1
      },
      faultInfoDetail: '',
      videoUrl: '',
      faultImageList: '',
      faultStatusList: [
        {
          value: '20',
          label: '关门时受阻挡'
        },
        {
          value: '37',
          label: '电动车乘梯'
        }
      ],
      faultDefinitionList: [
        {
          value: 0,
          label: '故障中'
        },
        {
          value: 1,
          label: '已恢复'
        }
      ],
      elevatorData: { vVillageId: '', vProjectId: '' }
    }
  },
  created() {
    // 获取物业部门
    this.searchList()
    reqElevatorProjectSelectList().then(res => {
      this.elevatorFormItemProjectSelectList = res.info.sort((a, b) =>
        a.v_project_name.localeCompare(b.v_project_name, 'zh-Hans-CN')
      )
    })

    searchVillageSelectList(this.buildParams({})).then(res => {
      this.villageFormItemSelectList = res.info
    })
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    searchList() {
      this.searchLoading = true
      this.tabelLoading = true

      let params = this.formatTimeParams(
        'dtReportTime',
        'dtEndTime',
        this.formItem
      )
      searchFaultList(this.buildParams(params)).then(res => {
        this.tabelData = res.info.list
        this.$refs['tblcomponents'].pageIndex = res.info.pageIndex
        this.$refs['tblcomponents'].pageSize = res.info.pageSize
        this.totalRecordCnt = res.info.totalRecordCnt
        this.tabelLoading = false
        this.searchLoading = false
      })
    },
    showDetail(id) {
      this.modaDetail = true
      getFaultDetail(id).then(res => {
        if (res.code === '0000') {
          this.faultInfoDetail = res.info.detail
          this.videoUrl = res.info.videoUrl
          this.faultImageList = res.info.imageList
        }
      })
    },
	eveType(type){
		if(type == 1){
			return "直梯"
			
		}else{
			return "扶梯"
		}
	},
    buildParams(params) {
      params.vProjectId = this.user.user.v_project_id
      return params
    },
    onChangeEndTime(e) {
      this.formItem.dtEndTime = e
    }
  },
  watch: {
    'formItem.villageId': function(newObj, OldObj) {
      if (newObj) {
        this.elevatorData.vVillageId = newObj
        ElevatorApi.searchElevatorNotPage(this.elevatorData).then(res => {
          this.elevatorSelectList = res.info
        })
      } else {
        this.formItem.vElevatorCode = ''
        this.elevatorSelectList = []
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ivu-modal-content {
  background-color: #f2f7fb;
}

/deep/.ivu-modal-header p,
/deep/.ivu-modal-header-inner {
  font-weight: 700;
  padding-left: 20px;
}

/deep/.ivu-modal-header {
  border: none;
}

/deep/.ivu-modal-body {
  padding-top: 0;
}

.ivu-select,
.ivu-date-picker {
  margin-right: 10px;
}

.title {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-size: 18px;
  font-weight: 700;
  padding-left: 20px;
  color: #fff;
}

.page {
  padding: 0 10px 0;
}

.content {
  padding: 10px;
}

.flexVideo {
  video {
    width: 100%;
  }
}

.TimeDiv::-webkit-scrollbar {
  display: none;
}

.ivu-col-offset-7 {
  margin-left: 37%;
}

/deep/ .ivu-tabs .ivu-tabs-tabpane {
  height: 400px;
}

.img-container {
  img:not(:first-child) {
    margin-top: 32px;
  }
}

.flexVideo video[data-v-37e4bc8e] {
  height: 400px;
}
</style>
